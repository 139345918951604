@import "variables";
@import "lib";
@import "~bootstrap";

.card {
    border: 1px solid #ccc;
}

.showrooms-container {
    .showroom {
        @extend .col-md-6;
        padding: 1rem;
        width: 100%;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            min-height: 250px;
        }

        a {
            border: 1px solid $lightgray;
            padding: 1.5rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            min-height: 100px;

            img {
                @extend .col-lg-4;
                height: 100%;
                width: 100%;
                max-height: 64px;
                padding: 0;
                object-fit: contain;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    width: auto;
                    margin: auto;
                    height: auto;
                }
            }

            .showroom-info {
                @extend .col;
                padding: 0 1.5rem;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .showroom a {
            flex-direction: column;
            align-items: flex-start;

            .showroom-info {
                margin-top: 1rem;
            }
        }
    }
}

#tagwalk-logo {
    height: 48px !important;
}
